import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FetchService } from '../../../services/Api';
import Header from '../../header/Header';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import style from './PizzaTvWeekExplorer.module.css';
import { useNavigate } from 'react-router-dom';
import { getReportName, getMonthlyReportName, DAYS } from '../Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { generatePDFWithSelenium } from '../PDFGeneration';

const PizzaTvWeekExplorer = (props) => {
  const navigate = useNavigate();
  const {
    whenSelectingCompany,
    selectedCompany,
    whenSelectingWeek,
    selectedWeek,
    whenSelectingMonth,
    selectedMonth,
    selectedWeekNumber,
    reportsOnly,
    monthly
  } = props;
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState(undefined);
  const [monthlyReports, setMonthlyReports] = useState(undefined);
  const inputFileRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [seleniumState, setSeleniumState] = useState(undefined);
  const [seleniumQueue, setSeleniumQueue] = useState([]);

  useEffect(() => {
    if (selectedCompany === undefined || selectedWeek === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/reports/company_pk/${selectedCompany}/week_stamp/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}/`,
      method: 'GET'
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        response.data.sort((a, b) => (a.pretty_name || a.name) > (b.pretty_name || b.name) ? 1 : (a.pretty_name || a.name) < (b.pretty_name || b.name) ? -1 : 0)
        setReports(response.data);
        getSeleniumState();
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [selectedCompany, selectedWeek])

  useEffect(() => {
    if (seleniumState === undefined) return;
    setSeleniumQueue((seleniumState.reports || []).map(r => r._id));
  }, [seleniumState])

  useEffect(() => {
    if (selectedCompany === undefined || selectedMonth === undefined) return;
    setMonthlyReports(undefined);
    let requestConf = {
      endpoint: `pizza_tv/report/monthly/company_pk/${selectedCompany}/month_stamp/${selectedMonth.getDate()}-${selectedMonth.getMonth() + 1}-${selectedMonth.getFullYear()}/`,
      method: 'GET',
      data: {
        regional: 1
      }
    }
    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        if (response.data && response.data.data.reports) {
          response.data.data.reports.sort((a, b) => a.pretty_name > b.pretty_name ? 1 : a.pretty_name < b.pretty_name ? -1 : 0)
          setMonthlyReports(response.data.data.reports);
        }
      },
      (err) => {
        window.alert(err.cause.response.data.err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [selectedCompany, selectedMonth])

  const getStateString = (state, reportsOnly) => {
    switch (state) {
      case 'waiting_initialization':
        return 'Initialize'
      case 'data selection':
        return 'Continue WIP'
      case 'scoreless data selection':
        return 'Continue WIP'
      case 'complete':
        return 'Edit Validated Report'
      case 'generated':
        return 'Edit Generated Report'
      case 'delivered':
        if (reportsOnly) return 'Weekly report'
        else return 'Edit Delivered Report'
      default:
        return 'Blocked'
    }
  }

  const getMonthlyReportStateString = (state) => {
    switch (state) {
      case 'complete':
        return 'Preview Report'
      case 'generated':
        return 'Preview Report'
      case 'delivered':
        return 'Preview Delivered Report'
      default:
        return 'Initialize Report'
    }
  }

  const getLastStepsString = (state) => {
    switch (state) {
      case 'generated':
        return 'Send email with Report PDF'
      case 'delivered':
        return 'Visit Pizza TV'
      default:
        return ''
    }
  }

  const getStateStyle = (state) => {
    switch (state) {
      case 'waiting_initialization':
        return {
          backgroundColor: '#d8d8d8',
          pointerEvents: 'none',
          color: '#11111B'
        }
      case 'data selection':
        return {
          backgroundColor: '#fff2cc',
          color: '#11111B'
        }
      case 'scoreless data selection':
        return {
          backgroundColor: '#fff2cc',
          color: '#11111B'
        }
      case 'complete':
        return {
          backgroundColor: '#00b050',
          color: '#FFFFFF'
        }
      case 'generated':
        return {
          backgroundColor: '#00b050',
          color: '#FFFFFF'
        }
      case 'delivered':
        return {
          backgroundColor: '#00b050',
          color: '#FFFFFF'
        }
      default:
        return {
          backgroundColor: '#d8d8d8',
          pointerEvents: 'none',
          color: '#11111B'
        }
    }
  }

  const blockedCheck = useCallback((e, venue, index) => {
    if (e.target.checked && selectedWeek !== undefined) {
      let alt = searchParams.get('alt') == '1' && !window.confirm("Elija el tipo de inicialización:\n- Aceptar: Versión original\n- Cancelar: Modo alternativo");
      let per_day_select = searchParams.get('pds') == '1' && !window.confirm("Elija el tipo de selección:\n- Aceptar: Semanal (versión original)\n- Cancelar: Diario (nuevo modo)");
      let skip_pizzas = !window.confirm("Elija el tipo de inicialización:\n- Aceptar: Versión original (pizzas de toda la semana)\n- Cancelar: Modo diario (comienza sin pizzas)");
      let requestConf = {
        endpoint: `pizza_tv/initialize_report/`,
        method: 'POST',
        dataType: 'JSON',
        data: {
          venue_pk: venue,
          week_stamp: `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`,
          week_number: selectedWeekNumber,
          alternative: alt,
          per_day_select: per_day_select,
          skip_pizzas: skip_pizzas,
        }
      }

      setLoading(true);
      FetchService(requestConf).then(
        (response) => {
          if (response.data._id) {
            let temp = [...reports];
            temp[index].state = 'data selection';
            temp[index]._id = response.data._id;
          }
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err)).finally((res) => setLoading(false));
    }
  }, [selectedWeek, reports, selectedWeekNumber, searchParams])

  const validate = useCallback((report, reportsOnly) => {
    if (selectedWeek === undefined) return;
    if (reportsOnly) navigate(`/live-pdf/venue/${report.name}/week/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`);
    else navigate(`/pizza-tv-validation/venue/${report.name}/week/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`);
  }, [selectedWeek, navigate])

  const monthlyReportActionsHandler = useCallback((state, region, index) => {
    if (['complete', 'generated', 'delivered'].includes(state)) {
      let url = `/monthly-report/company/${selectedCompany}/month/${selectedMonth.getDate()}-${selectedMonth.getMonth() + 1}-${selectedMonth.getFullYear()}${region ? `/region/${region}` : ''}`
      navigate(url);
    }
    else {
      let requestConf = {
        endpoint: `pizza_tv/initialize_monthly_report/`,
        method: 'POST',
        dataType: 'JSON',
        data: {
          company: selectedCompany,
          month_stamp: `${selectedMonth.getDate()}-${selectedMonth.getMonth() + 1}-${selectedMonth.getFullYear()}`,
          ...(region ? {
            region: region
          } : {})
        }
      }

      setLoading(true);
      FetchService(requestConf).then(
        (response) => {
          if (response.data._id) {
            let tempMonthlyReport = [...monthlyReports];
            tempMonthlyReport[index].state = 'complete';
            tempMonthlyReport[index]._id = response.data._id
            setMonthlyReports(tempMonthlyReport);
          }
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err)).finally((res) => setLoading(false));
    }
  }, [selectedMonth, selectedCompany, navigate, monthlyReports])

  const sendEmailPDF = useCallback((report, index, reports) => {
    if (!window.confirm('You are about to send an email with the attached Report to all registered recipients. Do you want to continue?')) { return }
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/report/pdf_email/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        // `report` comes from back's ReportsAPIView. INFO: `report.venue == report.doc.venue`
        reports: [{ ...report, venue: report.name, name: getReportName(new Date(report.week_stamp), report.venue, report.doc.franchise) }],
      },
      alertErrors: false
    }
    FetchService(requestConf)
      .then(response => {
        let alertMsg = response.data.msg;
        if (response.data.fails) {
          for (let fail of response.data.fails) {
            alertMsg += `\n${fail.report}: ${fail.err}`
          }
        }
        window.alert(alertMsg)
        if (!response.data.fails) {
          let temp = reports.map(report => { return { ...report } });
          temp[index].state = 'delivered';
          setReports(temp);
        }
      })
      .catch(err => {
        window.alert(err.cause.response.data.err);
        console.log(`Err "${err}" occurred while trying to send email`)
      })
      .finally(() => setLoading(false))
  }, [])

  const sendMonthlyEmailPDF = useCallback((report, index) => {
    if (!window.confirm('You are about to send an email with the attached Report to all registered recipients. Do you want to continue?')) { return }
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/report/pdf_email/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        reports: [{ ...report, name: getMonthlyReportName(new Date(report.month_stamp), report.doc.franchise, report.doc.region) }],
        company: 1
      },
      alertErrors: false
    }
    FetchService(requestConf)
      .then(response => {
        let alertMsg = response.data.msg;
        if (response.data.fails) {
          for (let fail of response.data.fails) {
            alertMsg += `\n${fail.report}: ${fail.err}`
          }
        }
        window.alert(alertMsg)
        if (!response.data.fails) {
          let temp = [...monthlyReports];
          temp[index].state = 'delivered';
          setMonthlyReports(temp);
        }
      })
      .catch(err => {
        window.alert(err.cause.response.data.err);
        console.log(`Err "${err}" occurred while trying to send email`)
      })
      .finally(() => setLoading(false))
  }, [monthlyReports])

  const lastSteps = useCallback((report, index) => {
    if (report.state === 'generated') sendEmailPDF(report, index, reports);
    else if (report.state === 'delivered') window.open(`/pizza-tv/venue/${report.name}?week=${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`, '_blank', 'noreferrer');
  }, [selectedWeek, reports, sendEmailPDF])

  const uploadReportCsv = useCallback((e) => {
    setLoading(true);
    const csv = e.target.files[0];
    let requestConf = {
      endpoint: `pizza_tv/csv/company_pk/${selectedCompany}/week_stamp/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}/`,
      method: 'POST',
      data: {
        csv: csv,
      }
    }
    FetchService(requestConf)
      .then(response => {
        window.alert(response.data.msg);
        let reportsCpy = [...reports];
        for (let i = 0; i < reportsCpy.length; i++) {
          if (reportsCpy[i].name === response.data.venue) {
            reportsCpy[i].state = 'delivered'
            break;
          }
        }
        setReports(reportsCpy);
      })
      .catch(err => {
      })
      .finally(() => {
        e.target.value = null;
        setLoading(false)
      })
  }, [selectedWeek, selectedCompany, reports])

  const deleteReport = useCallback((report, monthly) => {
    let alertMsg;
    if (monthly) alertMsg = `and month ${selectedMonth.getUTCDate()}-${selectedMonth.getUTCMonth() + 1}-${selectedMonth.getUTCFullYear()}`;
    else alertMsg = `and week ${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`;
    if (window.confirm(`You are about to delete report for store ${report.pretty_name || report.name} ${alertMsg}`)) {
      setLoading(true);
      let requestConf = {
        endpoint: `pizza_tv/report${monthly ? '/monthly' : ''}/delete/id/${report._id}/`,
        method: 'DELETE'
      }
      FetchService(requestConf)
        .then(response => {
          window.alert(response.data.msg);
          let reportsCpy;
          if (monthly) reportsCpy = [...monthlyReports];
          else reportsCpy = [...reports];
          for (let i = 0; i < reportsCpy.length; i++) {
            if (reportsCpy[i]._id === report._id) {
              reportsCpy[i].state = null;
              break;
            }
          }
          if (monthly) setMonthlyReports(reportsCpy);
          else setReports(reportsCpy);
        })
        .catch(err => {
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }, [selectedWeek, reports, selectedMonth, monthlyReports])

  const reportPreview = useCallback((report) => {
    let reportName = getMonthlyReportName(new Date(report.month_stamp), report.doc.franchise, report.doc.region);
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/report_preview/`,
      method: 'GET',
      data: {
        report_name: reportName,
        monthly: 1
      }
    }
    FetchService(requestConf)
      .then(response => {
        window.open(response.data.url, '_blank');
      })
      .catch(err => {
        console.log(err);
      })
      .finally(response => {
        setLoading(false);
      })
  }, [])

  const getSeleniumState = useCallback(() => {
    let requestConf = {
      endpoint: `pizza_tv/selenium/state/`,
      method: 'GET'
    }
    FetchService(requestConf)
      .then(response => {
        setSeleniumState(response.data)
      })
      .catch(err => console.log(err));
  }, [])

  const printReporstWithSelenium = () => {
    generatePDFWithSelenium(reports.filter(r => r.state === 'complete').map(r => ({_id: r._id, venue: r.name, doc: r.doc, week_stamp: r.week_stamp})), undefined, undefined, `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`, false);
    setSeleniumQueue(reports.filter(r => r.state === 'complete').map(r => r._id));
  }

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper} style={{ maxWidth: '1500px' }}>
        {
          monthly ? (
            <Header
              whenSelectingMonth={whenSelectingMonth}
              whenSelectingCompany={whenSelectingCompany}
              selectedCompany={selectedCompany}
              selectedMonth={selectedMonth} />
          ) : (
            <Header
              whenSelectingWeek={whenSelectingWeek}
              whenSelectingCompany={whenSelectingCompany}
              selectedCompany={selectedCompany}
              selectedWeek={selectedWeek} />
          )
        }

        <div className={style.pageContent}>
          {
            !reportsOnly && monthly !== true && selectedCompany && selectedWeek ? (
              <React.Fragment>
                <input
                  id='reportInputFile'
                  type='File'
                  ref={inputFileRef}
                  onChange={uploadReportCsv}
                  style={{ display: 'none' }} />
                <label
                  htmlFor='reportInputFile'
                  style={{
                    border: '1px solid #ccc',
                    padding: '6px 10px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    marginBottom: '30px'
                  }}>
                  Upload csv
                </label>
                <button style={{
                  border: '1px solid #ccc',
                  padding: '6px 10px',
                  cursor: 'pointer',
                  display: 'inline-block',
                  marginBottom: '30px',
                  marginLeft: '15px',
                  background: 'none',
                  fontSize: '16px'
                }} onClick={() => {
                  navigate(`/pizza-tv-print/company/${selectedCompany}/week/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`);
                }} >Print Reports</button>
                <button style={{
                  border: '1px solid #ccc',
                  padding: '6px 10px',
                  cursor: 'pointer',
                  display: 'inline-block',
                  marginBottom: '30px',
                  marginLeft: '15px',
                  background: 'none',
                  fontSize: '16px'
                }} onClick={() => {
                  printReporstWithSelenium()
                }} >Print Reports With Selenium</button>
                {
                  reports ? (
                    <span style={{ marginLeft: '15px' }}>{`Delivered: ${reports.filter(report => report.state === 'delivered').length}/${reports.filter(report => report.state).length}`}</span>
                  ) : (
                    null
                  )
                }
              </React.Fragment>
            ) : (
              null
            )
          }
          {
            reports !== undefined && !monthly ? (
              <table className={style.venuesTable}>
                <tbody>
                  <tr>
                    <th colSpan={2}>Store</th>
                    <th> Reviewed day </th>
                    <th colSpan={reportsOnly ? 3 : 4}>Status</th>
                  </tr>
                  {
                    reports.map((report, index) => {
                      return <tr key={index}>
                        {
                          !reportsOnly && report.state !== null && !monthly ? (
                            <td>
                              <button
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  fontSize: '20px'
                                }}
                                onClick={() => { deleteReport(report) }}><FontAwesomeIcon icon={faTrashAlt} /></button>
                            </td>
                          ) : (
                            <td></td>
                          )
                        }
                        <td><span>{report.pretty_name || report.name}</span></td>
                        {
                          report.reviewed_day ? (
                            <td> {report.reviewed_day.substr(0, report.reviewed_day.indexOf('T'))} </td>
                          ) : (
                            <td> (no review) </td>
                          )
                        }
                        <td>
                          {
                            reportsOnly && report.state !== 'delivered' ? (
                              'No report available'
                            ) : (
                              <button className={style.stateButton} style={getStateStyle(report.state)} onClick={() => validate(report, reportsOnly)}>{getStateString(report.state, reportsOnly)}</button>
                            )
                          }
                        </td>
                        {
                          reportsOnly ? (
                            null
                          ) : (
                            <td>
                              <div className={style.blockedCheck}>
                                <input
                                  style={report.state === null || report.state === 'waiting_initialization' ? { cursor: 'pointer' } : { cursor: 'grab', backgroundColor: '#d8d8d8', borderColor: '#d8d8d8' }}
                                  checked={report.state === null || report.state === 'waiting_initialization' ? false : true}
                                  disabled={report.state === null || report.state === 'waiting_initialization' ? false : true}
                                  onChange={(e) => {
                                    blockedCheck(e, report.name, index);
                                  }}
                                  type='checkbox'
                                  id={'blocked' + index}>
                                </input>
                                <label style={report.state === null || report.state === 'waiting_initialization' ? { color: '#11111B' } : { color: '#d8d8d8' }} htmlFor={'blocked' + index}>Mark QA as done</label>
                              </div>
                            </td>
                          )
                        }
                        {
                          report.track_of_days ? (
                            <td>
                              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px'}}>
                                {
                                  DAYS.map(day => {
                                    return <span style={{
                                      width: '25px',
                                      height: '25px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      backgroundColor: `${report.track_of_days[day].checked ? '#93c47e' : report.track_of_days[day].fetched ? '#f7b36c' : 'white'}`
                                    }}>{`${day[0]}`}</span>
                                  })
                                }
                              </div>
                            </td>
                          ) : (
                            <td></td>
                          )
                        }
                        {
                          ['generated', 'delivered'].includes(report.state) ? (
                            <React.Fragment>
                              {
                                (!report.scoreless_report && !reportsOnly) || (report.scoreless_report && !reportsOnly && report.state === 'generated') || (!report.scoreless_report && reportsOnly && report.state === 'delivered') ? (
                                  <td><button className={style.sendPDFButton} onClick={() => lastSteps(report, index)}>{getLastStepsString(report.state)}</button></td>
                                ) : (
                                  <td></td>
                                )
                              }
                              {
                                !reportsOnly && report.state === 'generated' ? (
                                  <td>
                                    {
                                      report.pizza_tv_emails.map((email, index) => {
                                        return <span key={index}><small>{email}</small><br /></span>
                                      })
                                    }
                                  </td>
                                ) : (
                                  <td></td>
                                )
                              }
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <td></td>
                              <td></td>
                            </React.Fragment>
                          )
                        }
                        {
                          seleniumQueue.includes(report['_id'])? (
                            <td>Queued for printing with selenium</td>
                          ) : (
                            <td></td>
                          )
                        }
                      </tr>
                    })
                  }
                </tbody>
              </table>
            ) : (
              null
            )
          }
          {
            monthly === true && selectedCompany && selectedMonth && monthlyReports !== undefined ? (
              <table className={style.venuesTable}>
                <tbody>
                  <tr>
                    <th colSpan={3}>Company</th>
                    <th colSpan={reportsOnly ? 3 : 4}>Action</th>
                  </tr>
                  {
                    monthlyReports.map((report, index) => {
                      return <tr key={index}>
                        {
                          ['generated', 'delivered'].includes(report.state) ? (
                            <td>
                              <button
                                style={{
                                  border: 'none',
                                  background: 'none',
                                  fontSize: '20px'
                                }}
                                onClick={() => reportPreview(report)}><FontAwesomeIcon icon={faDownload} /></button>
                            </td>
                          ) : (
                            <td></td>
                          )
                        }
                        {
                          ['complete', 'generated', 'delivered'].includes(report.state) ? (
                            <td>
                              <button
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  fontSize: '20px'
                                }}
                                onClick={() => { deleteReport(report, true) }}><FontAwesomeIcon icon={faTrashAlt} /></button>
                            </td>
                          ) : (
                            <td></td>
                          )
                        }
                        <td>
                          <span>{`${selectedCompany} ${report.pretty_name}`}</span>
                          {
                            report.stores ? (
                              <React.Fragment>
                                <br />
                                {
                                  report.stores.map((store, index) => {
                                    return <small style={{ display: 'block', fontSize: '10px', color: 'grey' }} key={index}><nbsp />{store}</small>
                                  })
                                }
                              </React.Fragment>
                            ) : (
                              null
                            )
                          }
                        </td>
                        <td>
                          <button className={style.stateButton} style={{
                            backgroundColor: '#00b050',
                            color: '#FFFFFF'
                          }} onClick={() => monthlyReportActionsHandler(report.state, report.region, index)}>{getMonthlyReportStateString(report.state)}</button>
                        </td>
                        {
                          report.state === 'generated' ? (
                            <React.Fragment>
                              <td><button className={style.sendPDFButton} onClick={() => sendMonthlyEmailPDF(report, index)}>Send email with Report PDF</button></td>
                              <td>
                                {
                                  (report.pizza_tv_emails || []).map((email, index) => {
                                    return <span key={index}><small>{email}</small><br /></span>
                                  })
                                }
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <td></td>
                              <td></td>
                            </React.Fragment>
                          )
                        }
                      </tr>
                    })
                  }
                </tbody>
              </table>
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default PizzaTvWeekExplorer;